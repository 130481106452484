// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-grenadines-claren-villas-js": () => import("./../../../src/pages/grenadines/claren-villas.js" /* webpackChunkName: "component---src-pages-grenadines-claren-villas-js" */),
  "component---src-pages-grenadines-clarence-gate-js": () => import("./../../../src/pages/grenadines/clarence-gate.js" /* webpackChunkName: "component---src-pages-grenadines-clarence-gate-js" */),
  "component---src-pages-grenadines-grenadines-resort-js": () => import("./../../../src/pages/grenadines/grenadines-resort.js" /* webpackChunkName: "component---src-pages-grenadines-grenadines-resort-js" */),
  "component---src-pages-grenadines-index-js": () => import("./../../../src/pages/grenadines/index.js" /* webpackChunkName: "component---src-pages-grenadines-index-js" */),
  "component---src-pages-grenadines-l-5-banana-js": () => import("./../../../src/pages/grenadines/l5-banana.js" /* webpackChunkName: "component---src-pages-grenadines-l-5-banana-js" */),
  "component---src-pages-grenadines-paramount-js": () => import("./../../../src/pages/grenadines/paramount.js" /* webpackChunkName: "component---src-pages-grenadines-paramount-js" */),
  "component---src-pages-grenadines-shonny-park-place-js": () => import("./../../../src/pages/grenadines/shonny-park-place.js" /* webpackChunkName: "component---src-pages-grenadines-shonny-park-place-js" */),
  "component---src-pages-grenadines-skyvilla-js": () => import("./../../../src/pages/grenadines/skyvilla.js" /* webpackChunkName: "component---src-pages-grenadines-skyvilla-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oceanna-cerulean-js": () => import("./../../../src/pages/oceanna/cerulean.js" /* webpackChunkName: "component---src-pages-oceanna-cerulean-js" */),
  "component---src-pages-oceanna-index-js": () => import("./../../../src/pages/oceanna/index.js" /* webpackChunkName: "component---src-pages-oceanna-index-js" */),
  "component---src-pages-oceanna-indigo-js": () => import("./../../../src/pages/oceanna/indigo.js" /* webpackChunkName: "component---src-pages-oceanna-indigo-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-property-mart-index-js": () => import("./../../../src/pages/property-mart/index.js" /* webpackChunkName: "component---src-pages-property-mart-index-js" */),
  "component---src-pages-property-mart-the-meadows-js": () => import("./../../../src/pages/property-mart/the-meadows.js" /* webpackChunkName: "component---src-pages-property-mart-the-meadows-js" */),
  "component---src-pages-subsidiaries-js": () => import("./../../../src/pages/subsidiaries.js" /* webpackChunkName: "component---src-pages-subsidiaries-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

